import { faUser } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar } from "../../../../components";
import { cn } from "../../../../components/utils/cn";
import { useGetProfile } from "../../../../services";
import { getImageUrl } from "../../../../utils/image";
import { LeadButton, type LeadButtonProps } from "./LeadButton";

interface Props extends LeadButtonProps {
  profileId?: string;
  organizationId: string;
}

export const LeadMemberButton = ({
  profileId,
  organizationId,
  className,
  ...props
}: Props) => {
  const { data } = useGetProfile(
    {
      organizationId,
      profileId: profileId ?? "",
    },
    {
      enabled: !!profileId,
    },
  );

  return (
    <LeadButton
      {...props}
      className={cn("lg:w-56 break-all overflow-hidden flex", className)}
    >
      {data ? (
        <Avatar
          // TODO: Default image
          imageUrl={
            data.profilePhotoId ? getImageUrl(data.profilePhotoId) : undefined
          }
          alt={
            typeof data.profileData.name === "string"
              ? data.profileData.name
              : "Profile"
          }
          className="w-10 h-10 shrink-0"
        />
      ) : (
        <FontAwesomeIcon
          width={20}
          height={20}
          icon={faUser}
          className="w-5 h-5 shrink-0"
        />
      )}

      <p className="truncate w-full">
        {typeof data?.profileData.name === "string"
          ? data.profileData.name
          : "member"}
      </p>
    </LeadButton>
  );
};

LeadMemberButton.displayName = "LeadMemberButton";
