import type React from "react";
import { useEffect, useState } from "react";
import withCustomStyling from "./HOC/withCustomStyling";
import type {
  AccountProfile,
  Branding,
  Profile,
  ProfileContactDetails,
  ProfileLinkConfig,
  SideProject,
} from "../types";
import { fetchProfileFromLinkId } from "../requests";
import PersonalDetailsSection from "./PersonalDetailsSection";
import Lottie from "react-lottie";
import animationData from "../assets/spinner-animation.json";
import Button, { ButtonStyleType } from "./atoms/Button";

interface ProfilePreviewProps {
  linkId: string;
  onEditButtonClick: () => void;
  onViewButtonClick: () => void;
}

const ProfilePreview: React.FC<ProfilePreviewProps> = ({
  linkId,
  onEditButtonClick,
  onViewButtonClick,
}) => {
  const [darkModeActive, setDarkModeActive] = useState<boolean>(false);
  const [profileLink, setProfileLink] = useState("");
  const [linkCreatorProfileId, setLinkCreatorProfileId] = useState("");
  const [isOwner, setIsOwner] = useState<boolean>(false);
  const [isCreator, setIsCreator] = useState<boolean>(false);
  const [viewDialogFullScreen, setViewDialogFullScreen] =
    useState<boolean>(false);
  const [isManager, setIsManager] = useState<boolean>(false);
  const [profilePhotoId, setProfilePhotoId] = useState<string>("");
  const [accountProfile, setAccountProfile] = useState<AccountProfile | null>(
    null,
  );
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const [selectedProjectPhotoAssetId, setSelectedProjectPhotoAssetId] =
    useState<string>("");
  const [profileLinkConfig, setProfileLinkConfig] =
    useState<ProfileLinkConfig | null>(null);
  const [currentProfileSideProjects, setCurrentProfileSideProjects] = useState<
    SideProject[]
  >([]);
  const [profileContactDetails, setProfileContactDetails] =
    useState<ProfileContactDetails | null>(null);
  const [profile, setProfile] = useState<Profile>({
    id: "",
    profile_name: "",
    name: "",
    title: "",
    pitch: "",
    ui_config: { dark_mode: false, use_profile_picture: true },
    roles_section: { order: 0, title: "", roles: [] },
    skills_section: { order: 0, title: "", skill_blocks: [] },
    side_projects_section: { order: 0, title: "", side_projects: [] },
    profile_tags: [],
    workplaces: [],
  });

  function getAssetUrl(branding: Branding | null) {
    if (branding === null) return null;
    if (branding.logotypes.length > 0) {
      return branding.logotypes[0].assetUrl;
    }
    return null;
  }

  function getAssetSize(branding: Branding | null) {
    if (branding === null) return null;
    if (branding.logotypes.length > 0) {
      return branding.logotypes[0].size;
    }
    return null;
  }

  useEffect(() => {
    if (linkId !== undefined) {
      fetchProfileFromLinkId(linkId).then((profileLinkResponseBody) => {
        const accountProfile = profileLinkResponseBody.accountProfile;
        setProfileContactDetails(profileLinkResponseBody.profileContactDetails);
        setProfile(accountProfile.profile);
        setIsOwner(accountProfile.isOwner);
        setProfilePhotoId(accountProfile.profilePhotoId ?? "");
        setLinkCreatorProfileId(accountProfile.linkCreatedByProfileId ?? "");
        setIsCreator(accountProfile.isCreator ?? false);
        setProfileLink(linkId);
        setAccountProfile(accountProfile);
        setIsManager(accountProfile.isManager ?? false);
        if (accountProfile.linkConfig) {
          setDarkModeActive(accountProfile.linkConfig.darkMode);
          setProfileLinkConfig(accountProfile.linkConfig);
        }

        const sortedSideProjects =
          accountProfile.profile.side_projects_section?.side_projects?.sort(
            (a: SideProject, b: SideProject) => {
              return a.order - b.order;
            },
          ) || [];

        setCurrentProfileSideProjects(sortedSideProjects);
      });
    }
  }, []);

  if (profileLinkConfig === null) {
    return (
      <div className="flex flex-1 h-[800px] flex-col justify-center align-center ">
        <div>
          <Lottie
            style={{ width: 80 }}
            options={{ animationData: animationData }}
          />
          <label className="font-bold">Loading profile...</label>
        </div>
      </div>
    );
  }
  const linkConfig = profileLinkConfig;
  const logoAssetUrl = getAssetUrl(linkConfig.branding || null);

  return (
    <div
      className="relative"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <PersonalDetailsSection
        isDarkModeActive={linkConfig.darkMode}
        profile={profile}
        isEditable={false}
        useProfilePicture={linkConfig.useProfilePicture}
        roundedProfilePicture={
          linkConfig.profileLinkPersonalInfoConfig.roundMode
        }
        profilePhotoId={profilePhotoId}
        onShowCropTool={() => {}}
        onProfileUpdate={() => {}}
        onAddProfileTagClick={() => {}}
        onProfileTagsUpdated={() => {}}
        hidePitch={!linkConfig.profileLinkPersonalInfoConfig.showPitch}
        logoAssetUrl={getAssetUrl(linkConfig.branding || null)}
        logoAssetSize={getAssetSize(linkConfig.branding || null)}
        isUploadingPhoto={false}
        additionalPitches={
          linkConfig.showCustomPitch === true && linkConfig.customPitch !== ""
            ? [linkConfig.customPitch]
            : []
        }
      />
      {isHovering ? (
        <div className="absolute right-0 top-0 w-full flex justify-end">
          <Button
            text="Copy link"
            style={ButtonStyleType.action}
            onButtonClick={() => {
              const baseUrl = process.env.REACT_APP_SITE_URL
                ? String(process.env.REACT_APP_SITE_URL)
                : "https://voc.studio";
              navigator.clipboard.writeText(baseUrl + "/p/" + profileLink).then(
                () => {
                  /* clipboard successfully set */
                },
                () => {
                  /* clipboard write failed */
                },
              );
            }}
          />
          <div className="min-w-[5px]" />
          <Button
            text="View"
            style={ButtonStyleType.primary}
            onButtonClick={() => {
              onViewButtonClick();
            }}
          />
          <div className="min-w-[5px]" />
          <Button
            text="Edit"
            style={ButtonStyleType.secondary}
            onButtonClick={() => {
              onEditButtonClick();
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default withCustomStyling(ProfilePreview);
