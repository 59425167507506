import type { PropsWithChildren } from "react";
import {
  BulletSectionTitle,
  SecondarySectionTitle,
} from "../../../../../components";
import { cn } from "../../../../../components/utils/cn";

type Props = PropsWithChildren<{
  title?: string;
  className?: string;
  secondarySection?: boolean;
}>;

export const LeadRowCardSection = ({
  title,
  className,
  secondarySection,
  children,
}: Props) => (
  <form className={cn("flex flex-col gap-2", className)}>
    {Boolean(title) &&
      (!secondarySection ? (
        <BulletSectionTitle>{title}</BulletSectionTitle>
      ) : (
        <SecondarySectionTitle>{title}</SecondarySectionTitle>
      ))}
    {children}
  </form>
);

LeadRowCardSection.displayName = "LeadRowCardSection";
