import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useReducer } from "react";
import QRCode from "react-qr-code";
import { IconButton, Radio } from "../../../../../components";
import { homeState } from "../../../../../state/home";
import { LeadRowCard } from "../card/LeadRowCard";
import { LeadRowCardSection } from "../card/LeadRowCardSection";
import { PanelContainer } from "./PanelContainer";

interface Props {
  profileLinkId?: string;
  leadId?: string;
}

export const QRPanel = ({ profileLinkId, leadId }: Props) => {
  const [testState, toggleTestState] = useReducer((state) => !state, true);

  const link = `https://voc.studio/p/${profileLinkId}`;

  const onEdit = () => {
    homeState.linkCreatorLeadId = leadId ?? null;
  };

  return (
    <PanelContainer className="gap-8 lg:gap-10">
      <div className="relative justify-self-center lg:justify-self-end flex flex-col justify-center items-center bg-gray-1 lg:bg-white rounded-4xl px-16 py-24 border border-gray-2 w-full lg:w-fit">
        <div className="flex flex-col items-center justify-center gap-2">
          {Boolean(profileLinkId) && (
            <QRCode size={56} value={link} className="rounded-2xl w-14 h-14" />
          )}
          <p className="font-black text-base text-center">Profile version</p>
        </div>

        <div className="absolute bottom-4 flex flex-row items-center">
          <IconButton
            icon={faSearch}
            onClick={() => window.open(link, "_blank")}
          />
          {Boolean(profileLinkId) && (
            <IconButton icon={faPenToSquare} onClick={onEdit} />
          )}
        </div>
      </div>

      <div className="flex flex-col gap-6 lg:justify-end">
        <LeadRowCard>
          <LeadRowCardSection title="Share settings">
            <Radio
              checked={testState}
              onCheckedChange={toggleTestState}
              label="Anyone with the link can access this profile version"
              labelClassName="font-normal"
            />
            <Radio
              checked={!testState}
              onCheckedChange={toggleTestState}
              label="Only visible with code"
              labelClassName="font-normal"
            />
          </LeadRowCardSection>
        </LeadRowCard>
        <LeadRowCard className="text-xs">
          Edit with a few clicks to customize Victors profile to fit the lead.
        </LeadRowCard>
      </div>
    </PanelContainer>
  );
};
