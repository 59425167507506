import {
  type UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import type {
  CreateLeadResponse,
  CreateLeadError,
  CreateLeadData,
  Lead,
} from "../../../client/types.gen";
import { createLeadMutation } from "../../../client/@tanstack/react-query.gen";
import { getLeadsQueryKey } from "./utils";
import { jsonParse } from "../../utils/parse";

export const useCreateLead = (
  options: UseMutationOptions<
    CreateLeadResponse,
    CreateLeadError,
    CreateLeadData
  > = {},
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...createLeadMutation(), // Use the generated mutation
    ...options, // Allow overriding with custom options

    // Add custom success handling
    onSuccess: (lead, variables, context) => {
      // Update the leads query cache with the new lead
      queryClient.setQueryData(
        getLeadsQueryKey(variables.path.organizationID),
        (oldData: Lead[] | undefined) => {
          const newLead = jsonParse<Lead>(lead);
          if (!oldData) return [newLead];
          return [...oldData, newLead];
        },
      );

      // Call the original onSuccess if provided
      options.onSuccess?.(lead, variables, context);
    },

    // Add custom error handling if needed
    onError: (error, variables, context) => {
      // Add any custom error handling here

      // Call the original onError if provided
      options.onError?.(error, variables, context);
    },

    // Add cache invalidation on completion
    onSettled: (data, error, variables, context) => {
      // Invalidate the leads query to ensure fresh data
      queryClient.invalidateQueries({
        queryKey: getLeadsQueryKey(variables.path.organizationID),
      });

      // Call the original onSettled if provided
      options.onSettled?.(data, error, variables, context);
    },
  });
};
