import {
  faAt,
  faBriefcase,
  faCircleInfo,
  faGlobe,
  faMagicWandSparkles,
  faPerson,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import {
  type PropsWithChildren,
  forwardRef,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { trim } from "validator";
import type { Lead } from "../../../../../client";
import {
  Button,
  Input,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  isEmailOrEmpty,
  isPhoneNumberOrEmpty,
  ImageButton,
  isUrlOrEmpty,
} from "../../../../../components";
import { iconForName } from "../../../../../components/TechIcons";
import { useAnimate } from "../../../../../hooks";
import { LeadRowCard } from "../card/LeadRowCard";
import { LeadRowCardSection } from "../card/LeadRowCardSection";
import { LeadRowCardSectionWithLink } from "../card/LeadRowCardSectionWithLink";
import { PanelContainer } from "./PanelContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaceFrown } from "@fortawesome/free-regular-svg-icons";

interface Props {
  lead?: Lead;
  disabled: boolean;
  onMutateLead: (lead: Partial<Lead>) => void;
  onRequestAssignmentInfo: (url: string) => void;
  onRequestAssignmentInfoWithInfo: (info: string) => void;
  onRequestWebsiteInfo: (url: string) => void;
  onSelectLogo: (logoUrl: string) => void;
  isEditingAssignmentLink: boolean;
  isEditingWebsiteLink: boolean;
  onEditAssigmentLinkClick: () => void;
  onEditWebsiteLinkClick: () => void;
  couldNotLoadFromUrl: boolean;
}

const MAX_VISIBLE_SKILLS = 5;

export const AssignmentPanel = ({
  lead,
  disabled,
  onMutateLead,
  onRequestAssignmentInfo,
  onRequestAssignmentInfoWithInfo,
  onRequestWebsiteInfo,
  onSelectLogo,
  isEditingAssignmentLink,
  isEditingWebsiteLink,
  onEditAssigmentLinkClick,
  onEditWebsiteLinkClick,
  couldNotLoadFromUrl,
}: Props) => {
  const [skillsParent] = useAnimate();
  const [expanded, toggleExpanded] = useReducer((expanded) => !expanded, false);

  const [localContactName, setLocalContactName] = useState(
    lead?.contactPersonName ?? "",
  );
  const [localContactEmail, setLocalContactEmail] = useState(
    lead?.contactPersonEmail ?? "",
  );
  const [localContactPhone, setLocalContactPhone] = useState(
    lead?.contactPersonPhone ?? "",
  );
  useEffect(() => {
    // Update local state when lead data changes
    setLocalContactName(lead?.contactPersonName ?? "");
    setLocalContactEmail(lead?.contactPersonEmail ?? "");
    setLocalContactPhone(lead?.contactPersonPhone ?? "");
  }, [
    lead?.contactPersonName,
    lead?.contactPersonEmail,
    lead?.contactPersonPhone,
  ]);

  const skills = useMemo(
    () =>
      lead?.assignmentInfo?.skills
        ?.map((skill) =>
          iconForName(skill)
            ? { icon: iconForName(skill), name: skill }
            : undefined,
        )
        .filter(Boolean),
    [lead?.assignmentInfo?.skills],
  );

  return (
    <PanelContainer>
      <div className="flex flex-col gap-6 lg:justify-start">
        <LeadRowCard type="solid">
          <LeadRowCardSection title="Contact information">
            <Input
              leftIcon={faPerson}
              label="Contact name"
              value={localContactName}
              onChange={(e) => setLocalContactName(e.target.value)}
              onBlur={(e) => {
                if (!(!lead && localContactName === "")) {
                  onMutateLead({ contactPersonName: localContactName });
                }
              }}
              transformValue={trim}
            />
            <Input
              leftIcon={faAt}
              label="E-mail"
              value={lead?.contactPersonEmail}
              onChange={(e) => setLocalContactEmail(e.target.value)}
              onBlur={(e) => {
                if (!(!lead && localContactEmail === "")) {
                  onMutateLead({ contactPersonEmail: localContactEmail });
                }
              }}
              transformValue={trim}
              validate={isEmailOrEmpty}
              type="email"
            />
            <Input
              leftIcon={faPhone}
              label="Phone number"
              value={lead?.contactPersonPhone}
              onChange={(e) => setLocalContactPhone(e.target.value)}
              onBlur={(e) => {
                if (!(!lead && localContactPhone === "")) {
                  onMutateLead({ contactPersonPhone: localContactPhone });
                }
              }}
              transformValue={trim}
              validate={isPhoneNumberOrEmpty}
              type="tel"
            />
          </LeadRowCardSection>
        </LeadRowCard>
        <LeadRowCard type="solid">
          {lead?.companyUrl && !isEditingWebsiteLink ? (
            <>
              <LeadRowCardSectionWithLink
                title="Company"
                linkUrl={lead.companyUrl}
                onEditClick={() => onEditWebsiteLinkClick()}
              ></LeadRowCardSectionWithLink>
              <LeadRowCardSection title="Name" secondarySection>
                <Input
                  disabled={disabled}
                  leftIcon={faGlobe}
                  label="Company website link"
                  value={lead?.companyName}
                />
              </LeadRowCardSection>
              <LeadRowCardSection title="Pick image">
                <div className="flex flex-row flex-wrap gap-2">
                  {lead?.logoSuggestions &&
                    lead?.logoSuggestions.map((logo, i) => (
                      <ImageButton
                        key={logo}
                        alt={`Logo suggestion ${i}`}
                        imageUrl={logo}
                        onClick={() => onSelectLogo(logo)}
                      />
                    ))}
                </div>
              </LeadRowCardSection>
              <LeadRowCardSection title="Description" secondarySection>
                <Input
                  leftIcon={faCircleInfo}
                  label="Client description"
                  value={lead.companyDesc}
                  rows={3}
                  transformValue={trim}
                />
              </LeadRowCardSection>
            </>
          ) : (
            <>
              <LeadRowCardSection title="Company">
                <Input
                  disabled={disabled}
                  leftIcon={faGlobe}
                  label="Company website link"
                  validate={isUrlOrEmpty}
                  value={lead?.companyUrl}
                  rightButton={{
                    label: "Go!",
                    onClick: onRequestWebsiteInfo,
                  }}
                />
              </LeadRowCardSection>
              <LeadRowCard
                className="text-xs bg-red-1 border-red-2 mt-3"
                type="dashed"
              >
                <div className="flex flex-1 items-center gap-3">
                  <FontAwesomeIcon
                    width={14}
                    height={14}
                    icon={faMagicWandSparkles}
                    className="w-4 h-4 shrink-0"
                  />
                  <p>Add company domain to fetch info</p>
                </div>
              </LeadRowCard>
            </>
          )}
        </LeadRowCard>
      </div>
      <LeadRowCard type="solid">
        {lead?.assignmentInfo &&
        !couldNotLoadFromUrl &&
        !isEditingAssignmentLink ? (
          <>
            <LeadRowCardSectionWithLink
              title="Add assignment"
              linkUrl={lead.assignmentInfo.assignmentUrl}
              onEditClick={() => onEditAssigmentLinkClick()}
            ></LeadRowCardSectionWithLink>
            <LeadRowCardSection title="Role" secondarySection>
              <Input
                leftIcon={faBriefcase}
                label="Role"
                value={lead?.title || lead?.assignmentInfo?.title}
                onFinishWriting={(title) => onMutateLead({ title })}
                transformValue={trim}
              />
            </LeadRowCardSection>
            <LeadRowCardSection title="Technical skills" secondarySection>
              <div className="flex flex-row flex-wrap gap-1 px-4">
                {skills?.map((skill) => (
                  <TooltipProvider key={skill?.name} delayDuration={0}>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <div className="w-6 h-6">{skill?.icon}</div>
                      </TooltipTrigger>
                      <TooltipContent className="mb-2">
                        <p>{skill?.name}</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                ))}
              </div>
            </LeadRowCardSection>
            <LeadRowCardSection title="All skills" secondarySection>
              <List ref={skillsParent}>
                {lead.assignmentInfo.skills
                  .slice(0, expanded ? undefined : MAX_VISIBLE_SKILLS)
                  .map((skill) => (
                    <li key={skill}>{skill}</li>
                  ))}
                {lead.assignmentInfo.skills.length > MAX_VISIBLE_SKILLS && (
                  <Button className="mt-2 w-max" onClick={toggleExpanded}>
                    {expanded ? "show less" : "show more"}
                  </Button>
                )}
              </List>
            </LeadRowCardSection>
            <LeadRowCardSection title="Responsibilities" secondarySection>
              <List>
                {lead.assignmentInfo.responsibilities.map((responsibility) => (
                  <li key={responsibility}>{responsibility}</li>
                ))}
              </List>
            </LeadRowCardSection>
            <LeadRowCardSection title="Location" secondarySection>
              <List>
                {lead.assignmentInfo.location.map((location) => (
                  <li key={location}>{location}</li>
                ))}
              </List>
            </LeadRowCardSection>
            <LeadRowCardSection title="Remote" secondarySection>
              <List>
                <li>{lead.assignmentInfo.remote}</li>
              </List>
            </LeadRowCardSection>
          </>
        ) : (
          <LeadRowCardSection title="Auto-fill magic">
            <p className="text-sm">Add link to see magic!</p>
            <Input
              disabled={disabled}
              leftIcon={faGlobe}
              label="Link"
              validate={isUrlOrEmpty}
              value={lead?.assignmentInfo?.assignmentUrl}
              rightButton={{
                label: "Go!",
                onClick: onRequestAssignmentInfo,
              }}
            />
            <Input
              disabled={disabled}
              leftIcon={faGlobe}
              label="Assignment info text"
              rows={5}
              rightButton={{
                label: "Go!",
                onClick: onRequestAssignmentInfoWithInfo,
              }}
            />
            {couldNotLoadFromUrl ? (
              <LeadRowCard
                className="text-xs bg-red-2 border-red-3 mt-3"
                type="dashed"
              >
                <div className="flex flex-1 items-center justify-between gap-3">
                  <FontAwesomeIcon
                    width={14}
                    height={14}
                    icon={faFaceFrown}
                    className="w-4 h-4 shrink-0"
                  />
                  <p>
                    Assignment info could not be fetched from the link. Please
                    try to paste the assignment description instead.
                  </p>
                </div>
              </LeadRowCard>
            ) : (
              <LeadRowCard
                className="text-xs bg-red-1 border-red-2 mt-3"
                type="dashed"
              >
                <div className="flex flex-1 items-center justify-between gap-3">
                  <FontAwesomeIcon
                    width={14}
                    height={14}
                    icon={faMagicWandSparkles}
                    className="w-4 h-4 shrink-0"
                  />
                  <p>
                    By adding an assignment link, VOCS will help you find a
                    matching candidate and help you write suiting resume
                    introductions.
                  </p>
                </div>
              </LeadRowCard>
            )}
          </LeadRowCardSection>
        )}
      </LeadRowCard>
    </PanelContainer>
  );
};

const List = forwardRef<HTMLUListElement, PropsWithChildren>(
  ({ children }, ref) => (
    <ul ref={ref} className="list-disc list-inside text-sm px-4">
      {children}
    </ul>
  ),
);

List.displayName = "List";

AssignmentPanel.displayName = "AssignmentPanel";
