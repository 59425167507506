import type React from "react";
import { forwardRef, useRef, useState } from "react";
import SiteNavigation from "./SiteNavigation";
import PageHeader from "./PageHeader";
import { useAuth0 } from "@auth0/auth0-react";

export interface SiteMenuProps {
  currentPath: string;
  isSignedIn: boolean;
}

function SiteMenu(
  props: SiteMenuProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const isHoveringSiteNavigation = useRef<boolean>(false);
  const isHoveringMenuButton = useRef<boolean>(false);
  const isShowingSiteNavigation = useRef<boolean>(false);
  const siteNavigationRef = useRef<HTMLDivElement>(null);

  const { loginWithRedirect, getAccessTokenSilently, logout, isAuthenticated } =
    useAuth0();

  const [isSiteNavigationOpen, setIsSiteNavigationOpen] =
    useState<boolean>(false);

  const onSiteNavigtionHoverEnd = () => {
    if (!isHoveringMenuButton.current) {
      console.log("4. Hover end - adding hide animation");
      setTimeout(() => {
        siteNavigationRef.current!.classList.add(
          "site-navigation-hide-animation",
        );
        siteNavigationRef.current!.classList.remove(
          "site-navigation-show-animation",
        );
        isShowingSiteNavigation.current = false;
        setIsSiteNavigationOpen(false);
      }, 300);
    }
  };

  const onMenuButtonHoverEnd = () => {
    if (!isHoveringSiteNavigation.current) {
      console.log("3. Hover end - adding hide animation");
      siteNavigationRef.current!.classList.add(
        "site-navigation-hide-animation",
      );
      siteNavigationRef.current!.classList.remove(
        "site-navigation-show-animation",
      );
      isShowingSiteNavigation.current = false;
      setIsSiteNavigationOpen(false);
    }
  };

  return (
    <div>
      <div style={{ background: "#e8eaed" }}>
        <div className="landing-center-wrapper">
          <PageHeader
            title="voc.studio"
            currentPath="landing"
            isSiteNavigationOpen={isSiteNavigationOpen}
            isSignedIn={
              localStorage.getItem("profiletool-auth-token") !== null &&
              localStorage.getItem("is-on-demo-account") === null
            }
            logoHeader
            onHoverStart={() => {
              if (isShowingSiteNavigation.current) {
                return;
              }

              siteNavigationRef.current!.classList.remove(
                "site-navigation-hide-animation",
              );
              siteNavigationRef.current!.classList.add(
                "site-navigation-show-animation",
              );
              isShowingSiteNavigation.current = true;
              setIsSiteNavigationOpen(true);
            }}
            onHoverEnd={() => {
              setTimeout(() => {
                onMenuButtonHoverEnd();
              }, 300);
            }}
            onMenuClick={() => {
              if (isShowingSiteNavigation.current) {
                //Hide menu
                siteNavigationRef.current!.classList.add(
                  "site-navigation-hide-animation",
                );
                siteNavigationRef.current!.classList.remove(
                  "site-navigation-show-animation",
                );
                isShowingSiteNavigation.current = false;
                setIsSiteNavigationOpen(false);
                return;
              }
              siteNavigationRef.current!.classList.remove(
                "site-navigation-hide-animation",
              );
              siteNavigationRef.current!.classList.add(
                "site-navigation-show-animation",
              );
              isShowingSiteNavigation.current = true;
              setIsSiteNavigationOpen(true);
            }}
          />
        </div>
      </div>
      <div style={{ background: "#f5f5f5" }}>
        <div className="landing-center-wrapper">
          <SiteNavigation
            currentPath={props.currentPath}
            isSignedIn={isAuthenticated}
            isSuperAccount={false}
            onHoverStart={() => {
              if (!isHoveringSiteNavigation.current) {
                isHoveringSiteNavigation.current = true;
              }
            }}
            onHoverEnd={() => {
              isHoveringSiteNavigation.current = false;
              if (isShowingSiteNavigation.current) {
                onSiteNavigtionHoverEnd();
              }
            }}
            ref={siteNavigationRef}
          />
        </div>
      </div>
    </div>
  );
}

export default forwardRef(SiteMenu);
