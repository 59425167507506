import "../App.css";

import { useState, useEffect, useCallback, useRef } from "react";

import PersonalDetailsSection from "../components/PersonalDetailsSection";

import { useNavigate } from "react-router-dom";
import toPDF from "react-to-pdf";

import type {
  Profile,
  SideProject,
  SectionTypeDescription,
  ProfileLinkConfig,
  Branding,
  AccountProfile,
  ProjectPhoto,
  ProfileContactDetails,
  ContentSuggestionChatMessage,
  ContentSuggestionRequestBody,
} from "../types";
import {
  createGuestUser,
  createProfileLinkQuery,
  fetchProfileFromLinkId,
  updateProfileVisit,
} from "../requests";
import SideProjectSection from "../components/SideProjectSection";

import "../components/components.css";
import { darkModeBlack } from "../colors";
import { useMediaQuery } from "react-responsive";
import { Dialog } from "@material-ui/core";
import ProjectPhotoViewer from "../components/ProjectPhotoViewer";
import WorkplaceSection from "../components/WorkplaceSection";
import EditorTitle from "../components/EditorTitle";
import { useParams } from "react-router-dom";
import { LeadButton } from "./dashboard/home/components/LeadButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faAt,
  faLink,
  faComment,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import vocsLogo from "../assets/voc_studio_logo.png";

import { toast } from "sonner";

import { Input, trim, InputRef, Button, LoadingSpinner } from "../components";

import { motion } from "framer-motion";
import QRCode from "react-qr-code";

interface RouteParams {
  [key: string]: string | undefined;
  profileLinkId?: string;
}

function ProfileViewer() {
  const { profileLinkId } = useParams<RouteParams>();

  const navigate = useNavigate();
  const [darkModeActive, setDarkModeActive] = useState<boolean>(false);
  const [pdfMode, setPdfMode] = useState<boolean>(false);

  const [profileLink, setProfileLink] = useState("");
  const [linkCreatorProfileId, setLinkCreatorProfileId] = useState("");
  const [viewDialogFullScreen, setViewDialogFullScreen] =
    useState<boolean>(false);
  const [isManager, setIsManager] = useState<boolean>(false);
  const [profilePhotoId, setProfilePhotoId] = useState<string>("");
  const [accountProfile, setAccountProfile] = useState<AccountProfile | null>(
    null,
  );

  const [selectedProjectPhotoAssetId, setSelectedProjectPhotoAssetId] =
    useState<string>("");
  const [profileLinkConfig, setProfileLinkConfig] =
    useState<ProfileLinkConfig | null>(null);
  const [currentProfileSideProjects, setCurrentProfileSideProjects] = useState<
    SideProject[]
  >([]);
  const [profileContactDetails, setProfileContactDetails] =
    useState<ProfileContactDetails | null>(null);
  const [profile, setProfile] = useState<Profile>({
    id: "",
    profile_name: "",
    name: "",
    title: "",
    pitch: "",
    ui_config: { dark_mode: false, use_profile_picture: true },
    roles_section: { order: 0, title: "", roles: [] },
    skills_section: { order: 0, title: "", skill_blocks: [] },
    side_projects_section: { order: 0, title: "", side_projects: [] },
    profile_tags: [],
    workplaces: [],
  });

  // Add ref for the content you want to convert
  const contentRef = useRef<HTMLDivElement>(null);

  const generatePDFAndClose = async () => {
    // Wait a bit for content to fully render
    await sleep(2000); // Adjust time as needed
    await generatePDF();
    await sleep(1000); // Wait a bit after PDF generation
    window.close();
  };

  const sleep = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));
  // PDF generation function
  const generatePDF = () => {
    if (!contentRef.current) return;
    setPdfMode(true);
    toPDF(contentRef, {
      filename: `${profile.name}-profile.pdf`,
      page: {
        margin: 20,
        format: "a4",
        orientation: "portrait",
      },
    }).finally(() => {
      setPdfMode(false);
    });
  };

  const isMobile = useMediaQuery({ query: "(max-width: 760px)" });

  const inputRef = useRef<InputRef>(null);
  const profileLinkQuestionToastId = useRef<string | number>();

  const [currentQuestion, setCurrentQuestions] = useState<string>("");
  const [currentAnswer, setCurrentAnswer] = useState<string>("");
  const [isLoadingProfileLinkQuestion, setIsLoadingProfileLinkQuestion] =
    useState<boolean>(false);

  const handleAddComment = useCallback((value: string) => {
    setCurrentQuestions(value);
    let messages: ContentSuggestionChatMessage[] = [
      { order: 0, content: value, fromUser: true },
    ];
    if (profileLinkId) {
      profileLinkQuestionToastId.current = toast.loading(
        "Checking profile for answers...",
      );
      setIsLoadingProfileLinkQuestion(true);
      createProfileLinkQuery(profileLinkId, messages).then(
        (resp: ContentSuggestionRequestBody) => {
          toast.success("Done!", { id: profileLinkQuestionToastId.current });
          setCurrentAnswer(resp.messages[1].content);
          setIsLoadingProfileLinkQuestion(false);
        },
      );
    }

    inputRef.current?.clear();
  }, []);

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (inputRef.current?.value) handleAddComment(inputRef.current?.value);
    },
    [handleAddComment],
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const pdfParam = queryParams.get("pdf");
    setPdfMode(pdfParam === "true");

    if (profileLinkId !== undefined) {
      fetchProfileFromLinkId(profileLinkId).then((profileLinkResponseBody) => {
        const accountProfile = profileLinkResponseBody.accountProfile;
        setProfileContactDetails(profileLinkResponseBody.profileContactDetails);
        setProfile(accountProfile.profile);
        setProfilePhotoId(accountProfile.profilePhotoId ?? "");
        setLinkCreatorProfileId(accountProfile.linkCreatedByProfileId ?? "");
        setProfileLink(profileLinkId);
        setAccountProfile(accountProfile);
        setIsManager(accountProfile.isManager ?? false);
        if (accountProfile.linkConfig) {
          setDarkModeActive(accountProfile.linkConfig.darkMode);
          setProfileLinkConfig(accountProfile.linkConfig);
          if (accountProfile.linkConfig.dynamic) {
            if (localStorage.getItem("op-guest-user-id") != null) {
              navigate(`/links/${profileLinkId}?dynamic=true`);
            } else {
              createGuestUser().then((guestUserId) => {
                localStorage.setItem("op-guest-user-id", guestUserId);
                navigate(`/links/${profileLinkId}?dynamic=true`);
              });
            }
          }
        }

        const sortedSideProjects =
          accountProfile.profile.side_projects_section?.side_projects?.sort(
            (a: SideProject, b: SideProject) => {
              return a.order - b.order;
            },
          ) || [];

        setCurrentProfileSideProjects(sortedSideProjects);

        // Check last visit time
        const lastVisitTime = localStorage.getItem(
          `lastVisit_${profileLinkId}`,
        );
        const shouldUpdateVisit =
          !lastVisitTime || hasHourPassed(parseInt(lastVisitTime));

        if (shouldUpdateVisit) {
          updateProfileVisit("", profileLinkId).then(() => {
            // Update the last visit time in localStorage
            localStorage.setItem(
              `lastVisit_${profileLinkId}`,
              Date.now().toString(),
            );
          });
        }

        if (pdfParam === "true") {
          generatePDFAndClose();
        }
      });
    }
  }, []);

  function hasHourPassed(lastVisitTime: number): boolean {
    const oneHour = 60 * 60 * 1000; // one hour in milliseconds
    return Date.now() - lastVisitTime > oneHour;
  }

  function getAssetUrl(branding: Branding | null) {
    if (branding === null) return null;
    if (branding.logotypes.length > 0) {
      return branding.logotypes[0].assetUrl;
    }
    return null;
  }

  function getAssetSize(branding: Branding | null) {
    if (branding === null) return null;
    if (branding.logotypes.length > 0) {
      return branding.logotypes[0].size;
    }
    return null;
  }

  function onCopyToClipboard(value: string, text: string) {
    navigator.clipboard.writeText(value).then(
      () => {
        toast.success(text);
      },
      () => {
        /* clipboard write failed */
      },
    );
  }

  const getSectionsInOrder = () => {
    const availableSections: SectionTypeDescription[] = [];
    if (profile.roles_section !== null && profile.roles_section !== undefined) {
      const role: SectionTypeDescription = {
        title: "Work Experience",
        info: "Describe your previous roles and workplaces",
        type: "roles",
        order: profile.roles_section!.order,
      };
      availableSections.push(role);
    }

    if (
      profile.skills_section !== null &&
      profile.skills_section !== undefined
    ) {
      const skills: SectionTypeDescription = {
        title: "Skills",
        info: "Express your skills",
        type: "skills",
        order: profile.skills_section!.order,
      };
      availableSections.push(skills);
    }

    if (
      profile.side_projects_section !== null &&
      profile.side_projects_section !== undefined &&
      profile.side_projects_section.side_projects !== null &&
      profile.side_projects_section.side_projects.length > 0
    ) {
      const skills: SectionTypeDescription = {
        title: "Projects",
        info: "Add any other project that does not fit under work experience",
        type: "side-projects",
        order: profile.side_projects_section!.order,
      };
      availableSections.push(skills);
    }

    const sectionsInOrder = availableSections.sort((a, b) => a.order - b.order);
    return sectionsInOrder;
  };

  if (profileLinkConfig === null) {
    return (
      <div className="flex flex-1 h-[800px] flex-col justify-center align-center ">
        <div>
          <LoadingSpinner size="medium" />
        </div>
      </div>
    );
  }
  const linkConfig = profileLinkConfig;
  const logoAssetUrl = getAssetUrl(linkConfig.branding || null);

  return (
    <div
      id="wrapper"
      style={{
        color: darkModeActive ? "white" : darkModeBlack,
        backgroundColor: darkModeActive ? darkModeBlack : "#fff",
      }}
    >
      <motion.div
        className="flex pt-[60px]"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.7 }}
      >
        {profileContactDetails && !pdfMode ? (
          <div
            className={`${
              !isMobile
                ? "sticky top-[60px] h-screen w-[250px]"
                : "absolute bottom-0 w-full max-h-[300px]"
            }
              left-0 overflow-y-auto z-50 flex-shrink-0`}
          >
            <div className="h-full">
              <div className="flex justify-center items-center px-8">
                <img
                  alt="logo"
                  src={
                    "https://pub-4b15203a4a0f4b26ab71deeffce0164a.r2.dev/" +
                    profileContactDetails?.logoId
                  }
                  className={`object-scale-contain`}
                  style={{
                    maxHeight: 100,
                  }}
                />
              </div>
              <div className="flex flex-col w-full pt-4 items-center pl-4 gap-1">
                <LeadButton
                  className={"w-100 xl:w-56"}
                  onClick={() => {
                    window.open(profileContactDetails.website, "_blank");
                  }}
                >
                  <img
                    alt="logo"
                    src={
                      "https://pub-4b15203a4a0f4b26ab71deeffce0164a.r2.dev/" +
                      profileContactDetails?.iconLogoId
                    }
                    className={`object-scale-contain`}
                    style={{
                      maxHeight: 20,
                    }}
                  />
                  <p className="truncate w-full">
                    Visit {profileContactDetails.name}
                  </p>
                </LeadButton>
                <LeadButton
                  className={"w-100 xl:w-56"}
                  onClick={() => {
                    onCopyToClipboard(
                      profileContactDetails.email,
                      "Email copied to clipboard",
                    );
                  }}
                >
                  <FontAwesomeIcon
                    width={20}
                    height={20}
                    icon={faAt}
                    className="w-5 h-5 shrink-0"
                  />
                  <p className="truncate w-full">
                    {profileContactDetails?.email}
                  </p>
                </LeadButton>
                {profileContactDetails.phoneNumber && (
                  <LeadButton
                    className={"w-100 xl:w-56"}
                    onClick={() => {
                      onCopyToClipboard(
                        profileContactDetails.phoneNumber,
                        "Phone number copied to clipboard",
                      );
                    }}
                  >
                    <FontAwesomeIcon
                      width={20}
                      height={20}
                      icon={faPhone}
                      className="w-5 h-5 shrink-0"
                    />
                    <p className="truncate w-full">
                      {profileContactDetails.phoneNumber}
                    </p>
                  </LeadButton>
                )}
                <LeadButton
                  className={"w-100 xl:w-56"}
                  onClick={() => {
                    onCopyToClipboard(
                      window.location.href,
                      "Profile version link copied!",
                    );
                  }}
                >
                  <FontAwesomeIcon
                    width={20}
                    height={20}
                    icon={faLink}
                    className="w-5 h-5 shrink-0"
                  />
                  <p className="truncate w-full">Copy link to this profile</p>
                </LeadButton>
              </div>
            </div>
          </div>
        ) : null}

        <div className="profile-viewer-content" ref={contentRef}>
          <div>
            <PersonalDetailsSection
              isDarkModeActive={linkConfig.darkMode}
              profile={profile}
              isEditable={false}
              useProfilePicture={linkConfig.useProfilePicture}
              roundedProfilePicture={
                linkConfig.profileLinkPersonalInfoConfig.roundMode
              }
              profilePhotoId={profilePhotoId}
              onShowCropTool={() => {}}
              onProfileUpdate={() => {}}
              onAddProfileTagClick={() => {}}
              onProfileTagsUpdated={() => {}}
              hidePitch={!linkConfig.profileLinkPersonalInfoConfig.showPitch}
              logoAssetUrl={
                profileContactDetails && pdfMode
                  ? profileContactDetails.logoId
                  : ""
              }
              logoAssetSize={getAssetSize(linkConfig.branding || null)}
              isUploadingPhoto={false}
              additionalPitches={
                linkConfig.showCustomPitch === true &&
                linkConfig.customPitch !== ""
                  ? [linkConfig.customPitch]
                  : []
              }
            />
            {linkConfig.profileLinkWorkplaceIds ? (
              linkConfig.profileLinkWorkplaceIds.length > 0 ? (
                <div className="mt-6">
                  <EditorTitle title="Work experience" pdfMode={pdfMode} />
                </div>
              ) : null
            ) : null}

            <WorkplaceSection
              key="workplace-section"
              configurable={false}
              pdfMode={pdfMode}
              workplaces={
                profile.workplaces != undefined
                  ? profile.workplaces.filter((wp) => {
                      if (!linkConfig.profileLinkWorkplaceIds) {
                        return false;
                      }
                      return (
                        linkConfig.profileLinkWorkplaceIds.indexOf(wp.id) > -1
                      );
                    })
                  : []
              }
              sideProjectSection={profile.side_projects_section!}
              isDarkModeActive={darkModeActive}
              sectionCount={getSectionsInOrder().length}
              isEditable={false}
              onAddItem={() => {}}
              onUpdateItem={() => {}}
              onRemoveItem={() => {}}
              onDeleteSectionClick={() => {}}
              onShowDialog={() => {}}
              onProjectOrderChange={() => {}}
              sideProjects={profile.side_projects_section?.side_projects}
              workplaceLinkConfig={linkConfig.workplaceLinkConfig}
            />
            {linkConfig.profileLinkProjects ? (
              linkConfig.profileLinkProjects.length > 0 ? (
                <div className="mt-6">
                  <EditorTitle title="Project" pdfMode={pdfMode} />
                </div>
              ) : null
            ) : null}

            <SideProjectSection
              profileId={profile.id}
              pdfMode={pdfMode}
              configurable={false}
              sideProjectSection={{
                ...profile.side_projects_section!,
                side_projects: currentProfileSideProjects,
              }}
              isDarkModeActive={darkModeActive}
              sectionCount={0}
              isEditable={false}
              workplaces={profile.workplaces}
              onAddSideProject={(newSideProject) => {}}
              onUpdateSideProject={(updatedSideProject) => {}}
              onRemoveSideProject={(sideProjectToRemove) => {}}
              onDeleteSectionClick={() => {}}
              projectPhotos={accountProfile?.projectPhotos ?? []}
              onShowDialog={() => {}}
              onProjectOrderChange={(projects: SideProject[]) => {
                setCurrentProfileSideProjects(projects);
              }}
              onProjectToggle={(project: SideProject, toggled: boolean) => {
                const config = Object.assign({}, profileLinkConfig);
                if (toggled) {
                  const indexOfProject =
                    currentProfileSideProjects.indexOf(project);

                  setProfileLinkConfig({
                    ...config,
                    profileLinkProjects: [
                      ...config.profileLinkProjects,
                      { projectId: project.id, order: indexOfProject },
                    ],
                  });
                } else {
                  const newProjects = config.profileLinkProjects.filter(
                    (p) => p.projectId !== project.id,
                  );
                  setProfileLinkConfig({
                    ...config,
                    profileLinkProjects: newProjects,
                  });
                }
              }}
              onProjectPhotoSelect={(assetId: string) => {
                setSelectedProjectPhotoAssetId(assetId);
              }}
              projectLinkConfing={linkConfig.projectsLinkConfig}
            />
          </div>
          {profileContactDetails && pdfMode && profileLink ? (
            <div className="flex flex-col w-full">
              <div className="w-full min-h-[2px] bg-gray-2 mb-12 mt-4" />
              <div className="flex flex-1 flex-row">
                <img
                  key="org-logo-pdf"
                  src={
                    "https://pub-4b15203a4a0f4b26ab71deeffce0164a.r2.dev/" +
                    profileContactDetails.iconLogoId +
                    "?nocache=12345"
                  }
                  className={`object-scale-contain mr-4`}
                  crossOrigin="anonymous"
                  alt="logo"
                  style={{ maxHeight: 100 }}
                />

                <div className="flex flex-row items-center ml-[24px]">
                  <FontAwesomeIcon
                    width={24}
                    height={24}
                    icon={faAt}
                    style={{ minHeight: "38px" }}
                    className={"w-5 h-5 shrink-0" + pdfMode ? " mt-2" : ""}
                  />
                  <div
                    className={"flex flex-col ml-2"}
                    onClick={() => {
                      onCopyToClipboard(
                        profileContactDetails.email,
                        "Email copied to clipboard",
                      );
                    }}
                  >
                    <p>Mail</p>
                    <p className="w-full">{profileContactDetails?.email}</p>
                  </div>
                </div>

                {profileContactDetails.phoneNumber && (
                  <div className="flex flex-row items-center ml-[24px]">
                    <FontAwesomeIcon
                      width={24}
                      height={24}
                      icon={faPhone}
                      style={{ minHeight: "38px" }}
                      className={"w-5 h-5 shrink-0" + pdfMode ? " mt-2" : ""}
                    />
                    <div
                      className={"flex flex-col ml-2"}
                      onClick={() => {
                        onCopyToClipboard(
                          profileContactDetails.email,
                          "Email copied to clipboard",
                        );
                      }}
                    >
                      <p>Call</p>
                      <p className="w-full">
                        {profileContactDetails?.phoneNumber}
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <div className="w-full min-h-[2px] bg-gray-2 mb-12 mt-12" />
              <div className="flex flex-1 flex-row px-8 justify-center items-center mt-2">
                <QRCode
                  size={62}
                  value={`https://voc.studio/p/${profileLinkId}`}
                  className="rounded-2xl w-16 h-16 mr-4"
                />
                <p
                  className={pdfMode ? "mb-4" : ""}
                >{`Visis ${profile.name}´s profile link to be able to ask questions and get descriptions of every skill mentioned!`}</p>
              </div>
              <div className="flex flex-1 justify-end">
                <div className="flex flex-col min-h-[100px] max-h-[100px] max-w-[100px] justify-end">
                  <span className="text-xs text-red-3 font-medium mb-[4px]">
                    Powered by
                  </span>
                  <img src={vocsLogo} alt="VOCS" />
                </div>
              </div>
            </div>
          ) : null}
          <div className="min-h-[250px]" />
        </div>
      </motion.div>
      {selectedProjectPhotoAssetId !== "" ? (
        <Dialog
          fullWidth
          fullScreen={viewDialogFullScreen}
          open={selectedProjectPhotoAssetId !== ""}
          onBackdropClick={() => {
            setSelectedProjectPhotoAssetId("");
            setViewDialogFullScreen(false);
          }}
        >
          <ProjectPhotoViewer
            profileId={profile.id}
            projectPhotos={accountProfile!.projectPhotos}
            onToggleFullScreen={(toggleFullSceenOn: boolean) => {
              setViewDialogFullScreen(toggleFullSceenOn);
            }}
            isEditable={false}
            assetId={selectedProjectPhotoAssetId}
            onProjectPhotosUpdate={(updatedProjectPhotos: ProjectPhoto[]) => {
              setAccountProfile({
                ...accountProfile!,
                projectPhotos: updatedProjectPhotos,
              });
            }}
            onClose={() => {
              setSelectedProjectPhotoAssetId("");
              setViewDialogFullScreen(false);
            }}
          />
        </Dialog>
      ) : null}

      {!isMobile && (
        <div className="fixed left-0 bottom-0 w-full min-h-[100px] flex flex-row bg-white">
          <div className="min-w-[250px] flex left-0 bottom-0">
            <div className="flex flex-col min-h-[100px] max-h-[100px] max-w-[100px] justify-center ml-[32px]">
              <span className="text-xs text-red-3 font-medium mb-[4px]">
                Powered by
              </span>
              <img src={vocsLogo} alt="VOCS" />
            </div>
          </div>
          <div className="flex flex-1 items-center min-h-[100px] profile-viewer-content">
            <div className="w-full">
              {currentQuestion === "" && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    type: "spring",
                    stiffness: 40,
                    damping: 10,
                  }}
                  className="self-center flex flex-col"
                >
                  <form onSubmit={handleSubmit} className="w-full">
                    <Input
                      ref={inputRef}
                      leftIcon={faComment}
                      label="Ask anything about this profile..."
                      transformValue={trim}
                      onBlur={(input) => console.log(input)}
                      rightButton={{
                        label: "Ask!",
                        type: "submit",
                        onClick: handleAddComment,
                      }}
                      disabled={isLoadingProfileLinkQuestion}
                    />
                  </form>
                </motion.div>
              )}

              {currentQuestion !== "" && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    type: "spring",
                    stiffness: 40,
                    damping: 10,
                  }}
                  className="self-center flex items-center justify-center flex-col"
                >
                  <div className="w-full">
                    <Input
                      ref={inputRef}
                      leftIcon={faComment}
                      label=""
                      transformValue={trim}
                      onBlur={(input) => console.log(input)}
                      value={currentQuestion + "\n\n" + currentAnswer}
                      rows={5}
                    />
                  </div>
                  <Button
                    className="my-2 mt-4"
                    type={"button"}
                    onClick={() => {
                      setCurrentQuestions("");
                      setCurrentAnswer("");
                    }}
                  >
                    Ask a new question
                  </Button>
                </motion.div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProfileViewer;
