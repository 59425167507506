import type { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faFilePdf, faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import {
  faBuildingCircleCheck,
  faBuildingCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PopoverMenu } from "../../../../../components";
import { cn } from "../../../../../components/utils/cn";

interface Props {
  onArchive: () => void;
  profileLinkId?: string;
}

export const LeadMenu = ({ onArchive, profileLinkId }: Props) => {
  return (
    <PopoverMenu>
      {profileLinkId ? (
        <LeadMenuRow
          icon={faFilePdf}
          text="Download as PDF"
          onClick={() =>
            window.open(
              window.location.origin + `/p/${profileLinkId}?pdf=true`,
              "_blank",
            )
          }
        />
      ) : null}
      <LeadMenuRow
        icon={faBuildingCircleCheck}
        text="Turn into contract"
        onClick={() => console.log("Menu item clicked")}
      />
      <LeadMenuRow
        icon={faBuildingCircleXmark}
        text="Archive lead"
        onClick={onArchive}
      />
    </PopoverMenu>
  );
};

interface LeadMenuRowProps {
  onClick: () => void;
  icon: IconDefinition;
  text: string;
  iconClassName?: string;
}

const LeadMenuRow = ({
  icon,
  text,
  onClick,
  iconClassName,
}: LeadMenuRowProps) => (
  <PopoverMenu.Item onClick={onClick}>
    <FontAwesomeIcon
      width={14}
      height={14}
      icon={icon}
      className={cn("shrink-0", iconClassName)}
    />
    <span className="text-sm text-black">{text}</span>
  </PopoverMenu.Item>
);

LeadMenu.displayName = "LeadMenu";
