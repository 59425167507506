import type { Options } from "@hey-api/client-fetch";
import { type UseMutationOptions, useMutation } from "@tanstack/react-query";
import type {
  PostV1QueryAssignmentData,
  PostV1QueryAssignmentError,
  PostV1QueryAssignmentResponse,
} from "../../client";
import { postV1QueryAssignmentMutation } from "../../client/@tanstack/react-query.gen";

const mutationKey = ["requestAssignmentInfo"];

export const useRequestAssignmentInfo = (
  opts?: UseMutationOptions<
    PostV1QueryAssignmentResponse,
    PostV1QueryAssignmentError,
    Options<PostV1QueryAssignmentData>
  >,
) => {
  return useMutation({
    ...postV1QueryAssignmentMutation(),
    mutationKey,
    ...(opts ?? {}),
  });
};
