import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "../../../../components/utils/cn";
import { LeadButton, type LeadButtonProps } from "./LeadButton";
import { getImageUrl } from "../../../../utils/image";
import { Avatar } from "../../../../components";

interface Props extends LeadButtonProps {
  title?: string;
  logoId?: string;
  companyName?: string;
}

export const LeadAssignmentButton = ({
  title,
  logoId,
  companyName,
  className,
  ...props
}: Props) => (
  <LeadButton {...props} className={cn("w-40 xl:w-56 flex", className)}>
    {logoId ? (
      <Avatar
        // TODO: Default image
        imageUrl={logoId ? getImageUrl(logoId) : undefined}
        alt={"logo"}
        className="w-10 h-10 shrink-0"
      />
    ) : (
      <FontAwesomeIcon
        width={20}
        height={20}
        icon={faBriefcase}
        className="w-5 h-5 shrink-0"
      />
    )}

    <div className="flex flex-col">
      <p className="truncate w-full">{title ?? "Assignment"}</p>
      <p className="truncate w-full">{companyName ?? ""}</p>
    </div>
  </LeadButton>
);

LeadAssignmentButton.displayName = "LeadAssignmentButton";
