import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  acceptOrganizationInvite,
  createAccount,
  signIn,
  validateCode,
} from "../requests";
import EmailInput from "../components/EmailInput";
import ValidationCodeInput from "../components/ValidationCodeInput";
import { useDispatch } from "react-redux";
import type { UserState } from "../userReducer";

import SiteMenu from "../components/SiteMenu";
import type { AxiosError } from "axios";
import LinkButton from "../components/LinkButton";
import { useAuth0 } from "@auth0/auth0-react";
import Lottie from "react-lottie";
import animationData from "../assets/spinner-animation.json";
import type { SignInResponseBody } from "../types";
import { LoadingSpinner } from "../components";

function Auth() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isWaitingForValidationCode, setIsWaitingForValidatingCode] =
    useState<boolean>(false);
  const [isInSignUpMode, setIsInSignUpMode] = useState<boolean>(false);
  const [emailInput, setEmailInput] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [newProfileId, setNewProfileId] = useState<string>("");

  const [validationCodeResultMessage, setValidationCodeResultMessage] =
    useState<string>("");
  const [isEmailInputLoading, setIsEmailInputLoading] =
    useState<boolean>(false);
  const [isValidationInputLoading, setIsValidationInputLoading] =
    useState<boolean>(false);

  const { loginWithRedirect, getAccessTokenSilently, logout, isAuthenticated } =
    useAuth0();

  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {},
        });

        signIn(accessToken)
          .then((signInResponseBody: SignInResponseBody) => {
            localStorage.setItem(
              "current-profile-id",
              signInResponseBody.profileId,
            );
            localStorage.setItem(
              "current-org-id",
              signInResponseBody.organizationId,
            );
            if (
              localStorage.getItem("redirect_after_auth") != null &&
              !signInResponseBody.isNewUser
            ) {
              const redirectUrl: string =
                localStorage.getItem("redirect_after_auth") ?? "";
              localStorage.removeItem("redirect_after_auth");
              navigate(redirectUrl);
              return;
            }

            if (signInResponseBody.isNewUser) {
              navigate("onboarding/" + signInResponseBody.profileId);
              return;
            } else {
              if (localStorage.getItem("op-pending-org-invite-code")) {
                const inviteCode = localStorage.getItem(
                  "op-pending-org-invite-code",
                );
                acceptOrganizationInvite(inviteCode!).then((orgId: string) => {
                  navigate("/dash/" + orgId);
                });
                return;
              }
            }

            if (signInResponseBody.organizationId !== "") {
              navigate("/dash/" + signInResponseBody.organizationId);
              return;
            }

            navigate("/profiles/" + signInResponseBody.profileId);
          })
          .catch((error: AxiosError) => {
            console.log("3. Sign in request error" + error);
          });
      } catch (e) {
        console.log(e);
      }
    };

    getUserMetadata();
  }, []);

  function parseJwt(token: string | null) {
    if (token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
          .join(""),
      );

      return JSON.parse(jsonPayload);
    }
  }

  return (
    <div className="absolute ml-auto left-0 right-0 top-[50%]">
      <LoadingSpinner size="medium" />
    </div>
  );

  return (
    <div style={{ minWidth: "100%" }}>
      <SiteMenu currentPath="auth" isSignedIn={false} />
      <button onClick={() => loginWithRedirect()}>Log In</button>
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",

          justifyContent: "center",
          alignItems: "center",
          justifyItems: "center",
          alignContent: "center",
          paddingTop: "200px",
          backgroundColor: "#e8eaed",
        }}
      >
        {isWaitingForValidationCode ? (
          <ValidationCodeInput
            validationMessage={validationCodeResultMessage}
            onValidateClick={(validationCode) => {
              setIsValidationInputLoading(true);
              validateCode(emailInput, validationCode)
                .then((userState: UserState) => {
                  dispatch({ type: "SIGNED_IN", payload: userState });
                  setIsValidationInputLoading(false);
                  if (isInSignUpMode) {
                    navigate("/controlboard?onboarding=true");
                    return;
                  }
                  navigate("/controlboard");
                })
                .catch((error) => {
                  setIsValidationInputLoading(false);
                  setValidationCodeResultMessage(
                    "Invalid validation code, try again.",
                  );
                });
            }}
            isLoading={isValidationInputLoading}
          />
        ) : (
          <EmailInput
            onSignInClick={(email, approvedTerms) => {
              setIsEmailInputLoading(true);
              if (isInSignUpMode) {
                createAccount(emailInput)
                  .then((profileId: string) => {
                    setNewProfileId(profileId);
                    setIsWaitingForValidatingCode(true);
                    setIsEmailInputLoading(false);
                  })
                  .catch((error: AxiosError) => {
                    setIsEmailInputLoading(false);
                  });
              }
              signIn(email)
                .then((response) => {
                  setIsWaitingForValidatingCode(true);
                  setIsEmailInputLoading(false);
                })
                .catch((error: AxiosError) => {
                  if (error?.response?.status === 406) {
                    setEmailError("No account found for email");
                  }
                  setIsEmailInputLoading(false);
                });
            }}
            isSignUp={isInSignUpMode}
            onSignUpClick={() => setIsInSignUpMode(true)}
            emailInput={emailInput}
            onEmailInputChange={(input: string) => {
              setEmailInput(input);
            }}
            isLoading={isEmailInputLoading}
            errorMessage={emailError}
          />
        )}
        {!isWaitingForValidationCode &&
        !isEmailInputLoading &&
        !isValidationInputLoading ? (
          <LinkButton
            title={
              isInSignUpMode
                ? "Already have an account? Sign in"
                : "No account yet? Sign up"
            }
            handleClick={() => {
              setIsInSignUpMode(!isInSignUpMode);
            }}
          />
        ) : null}
      </div>
    </div>
  );
}

export default Auth;
