import { faFileLines } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QRCode from "react-qr-code";
import { cn } from "../../../../components/utils/cn";
import { LeadButton, type LeadButtonProps } from "./LeadButton";

interface Props extends LeadButtonProps {
  profileLinkId?: string;
}

export const LeadQRButton = ({ profileLinkId, className, ...props }: Props) => (
  <LeadButton {...props} className={cn("flex", className)}>
    {profileLinkId ? (
      <QRCode
        size={40}
        value={`https://voc.studio/p/${profileLinkId}`}
        className="rounded-xl w-10 h-10 shrink-0"
      />
    ) : (
      <FontAwesomeIcon
        width={20}
        height={20}
        icon={faFileLines}
        className="w-5 h-5 shrink-0"
      />
    )}
    <p className={cn(!profileLinkId && "pr-5")}>profile version</p>
  </LeadButton>
);

LeadQRButton.displayName = "LeadQRButton";
