import type { PropsWithChildren } from "react";
import { BulletSectionTitle, Button, Input } from "../../../../../components";
import { cn } from "../../../../../components/utils/cn";

type Props = PropsWithChildren<{
  title?: string;
  className?: string;
  linkUrl: string;
  onEditClick: () => void;
}>;

export const LeadRowCardSectionWithLink = ({
  title,
  className,
  linkUrl,
  onEditClick,
  children,
}: Props) => (
  <form className={cn("flex flex-col gap-2", className)}>
    <div className="flex items-center justify-between">
      {Boolean(title) && <BulletSectionTitle>{title}</BulletSectionTitle>}
      <div className="flex items-center gap-2 grow justify-end">
        <Input value={linkUrl} disabled inputClassName="p-2 rounded" />
        <Button variant="primary" onClick={onEditClick}>
          Edit
        </Button>
      </div>
    </div>
    {children}
  </form>
);

LeadRowCardSectionWithLink.displayName = "LeadRowCardSection";
