import { HeroHeader } from "./components/HeroHeader";
import landing1 from "../../assets/landing_1.png";
import landing2 from "../../assets/landing_2.png";
import landing3 from "../../assets/landing_3.png";
import landing4 from "../../assets/landing_4.png";
import landing5 from "../../assets/landing_5.png";
import { LoadingSpinner } from "../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignIn } from "@fortawesome/free-solid-svg-icons";
import { LeadButton } from "../dashboard/home/components/LeadButton";
import { useAuth0 } from "@auth0/auth0-react";

export const Component = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div className="min-h-[100%] h-[100%]">
      <div className="absolute top-0 left-0 w-full flex px-8 justify-center items-center">
        <LoadingSpinner size="small" />
        <LeadButton onClick={() => loginWithRedirect()}>
          <FontAwesomeIcon icon={faSignIn} size="2x" className="text-red-3" />
        </LeadButton>
      </div>
      <div className="min-h-[80%] bg-red-1 flex items-center">
        <div className="max-w-[1600px] mx-auto flex items-center justify-center p-6">
          <HeroHeader className="text-center">
            {`Get you and your consultants
            straight to the top of the resume pile`}
          </HeroHeader>
        </div>
      </div>

      <div className="bg-red-2">
        <div className="max-w-[1600px] mx-auto flex flex-col lg:flex-row-reverse gap-6 p-4 lg:p-12 justify-center lg:justify-start">
          <div className="flex-1 flex flex-col gap-5 justify-center items-center lg:justify-start lg:text-left lg:items-start">
            <h1 className="text-7xl font-bold text-white">1.</h1>
            <HeroHeader className="text-4xl">
              Create Leads by pairing your consultants with potential clients
            </HeroHeader>
            <p className="font-sono whitespace-pre-line">
              {`vocs makes suggestions based
                on assignment description and client
              needs`}
            </p>
          </div>
          <div className="lg:pt-20">
            <img className="lg:w-[500px]" src={landing1} alt="leads" />
          </div>
        </div>
      </div>

      <div className="bg-red-2 flex pt-20">
        <div className="max-w-[1600px] lg:min-w-[1600px] mx-auto flex flex-col items-center py-4 px-4 lg:py-12 lg:px-12 text-center lg:text-left">
          <div className="lg:mr-10 lg:self-start">
            <h1 className="text-6xl font-bold text-white">2.</h1>
            <HeroHeader className="text-4xl">
              Auto-generate Unique Profile Versions
            </HeroHeader>
          </div>
          <img className="mt-10" src={landing2} alt="leads" />
        </div>
      </div>

      <div className="bg-red-2 pt-20">
        <div className="max-w-[1600px] mx-auto flex flex-col lg:flex-row-reverse p-12 lg:text-left text-center">
          <div className="flex-1 flex flex-col gap-5 justify-start">
            <h1 className="text-6xl font-bold text-white">3.</h1>
            <HeroHeader className="text-3xl">
              Tweak them to fit the assignment
            </HeroHeader>
            <p className="font-sono whitespace-pre-line">
              {`vocs generates profile
               introductions & suggestions
               based onpotential client needs`}
            </p>
          </div>
          <img className="lg:w-[600px] lg:pt-28" src={landing3} alt="leads" />
        </div>
      </div>

      <div className="bg-red-2 pt-20">
        <div className="max-w-[1600px] mx-auto flex flex-1 flex-col items-center lg:py-12 lg:px-32 p-4 text-center lg:text-left relative">
          <div className="flex flex-col justify-self-start lg:justify-start justify-center items-center text-center lg:text-left">
            <HeroHeader className="text-3xl">
              Med några klick har du precis ökat era chanser!
            </HeroHeader>
          </div>
          <img src={landing4} alt="leads" />
          <p className="font-sono whitespace-pre-line absolute top-[60%] right-[20%]">
            {`Med VOCS blir du, din konsult
              & ditt brand ihågkomna

            Rätt kandidat till rätt uppdrag`}
          </p>
        </div>
      </div>

      <div className="bg-red-2">
        <div className="max-w-[1600px] mx-auto flex flex-1 flex-col items-center p-4 lg:py-12 lg:px-32">
          <HeroHeader className="text-4xl">
            Interaktiv CV profil, optimerad för dig & mottagaren.
          </HeroHeader>
          <img className="mt-10 w-[800px]" src={landing5} alt="leads" />
        </div>
      </div>
    </div>
  );
};

Component.displayName = "LandingPage";

export default Component;
