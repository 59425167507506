import type { PropsWithChildren } from "react";
import { cn } from "../../../../../components/utils/cn";

type Props = PropsWithChildren<{
  type?: "dashed" | "solid";
  className?: string;
}>;

export const LeadRowCard = ({ type = "solid", children, className }: Props) => (
  <div
    className={cn(
      "p-4 rounded-2xl border flex flex-col gap-4 h-fit",
      type === "solid" && "bg-white border-gray-3",
      type === "dashed" && "border-dashed border-gray-4",
      className,
    )}
  >
    {children}
  </div>
);

LeadRowCard.displayName = "LeadRowCard";
