import type { PropsWithChildren } from "react";
import { cn } from "../../../components/utils/cn";

type Props = PropsWithChildren<{
  className?: string;
}>;

export const HeroHeader = ({ children, className }: Props) => (
  <p
    className={cn(
      "text-3xl lg:text-6xl text-red-3 font-regular whitespace-pre-line font-paytone",
      className,
    )}
  >
    {children}
  </p>
);

HeroHeader.displayName = "HeroHeader";
