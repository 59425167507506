import React, { useState } from "react";
import { type ImageUpload, ProfileComponentProps } from "../types";
import "./components.css";
import animationData from "../assets/spinner-animation.json";
import Lottie from "react-lottie";
import DefaultIcon from "../assets/op_o.png";

interface Props {
  id: string;
  allowEdit: boolean;
  photoAssetId?: string;
  isLoading: boolean;
  round: boolean;
  onImageSelected: (imageUpload: ImageUpload) => void;
}

export default function ProfilePhoto(props: Props) {
  const [hover, setHover] = useState<boolean>(false);

  if (!props.allowEdit) {
    return (
      <img
        alt="profile-photo"
        crossOrigin="anonymous"
        className={`w-[110px] h-[110px] object-cover ${props.round ? "rounded-full" : ""}`}
        src={
          props.photoAssetId
            ? `https://pub-4b15203a4a0f4b26ab71deeffce0164a.r2.dev/${props.photoAssetId}?nocache=12345`
            : DefaultIcon
        }
      />
    );
  }

  return (
    <div
      className={
        "min-w-[110px] min-h-[110px] max-w-[110px] max-h-[110px] border-op-dark relative overflow-hidden" +
        (props.allowEdit ? " hover:border-2" : "") +
        (props.round ? " rounded-[55px]" : "")
      }
      onClick={() => {
        if (props.allowEdit) {
          document.getElementById("logo-uploader-" + props.id)?.click();
        }
      }}
      onMouseEnter={() => {
        if (!props.allowEdit) {
          return;
        }
        setHover(true);
      }}
      onMouseLeave={() => setHover(false)}
    >
      {!props.isLoading ? (
        <div>
          <img
            alt="profile-photo"
            className="object-cover min-h-[110px]"
            src={
              props.photoAssetId
                ? "https://pub-4b15203a4a0f4b26ab71deeffce0164a.r2.dev/" +
                  props.photoAssetId
                : DefaultIcon
            }
          />
          {hover ? (
            <div className="min-h-[30px] bg-op-dark absolute bottom-0 left-0 text-white text-sm w-full text-center items-center justify-center flex">
              Click to change
            </div>
          ) : null}

          <input
            className="hidden"
            type="file"
            id={"logo-uploader-" + props.id}
            onChange={(event) => {
              if (event.target.files === null) return;

              const file = event.target.files[0];
              const reader = new FileReader();

              reader.onload = (e: ProgressEvent<FileReader>) => {
                const formatInfo = (e.target?.result as string).split(",")[0];
                const base64String = (e.target?.result as string).split(",")[1];
                const format = formatInfo.split(";")[0].split(":")[1];

                const imageUpload: ImageUpload = {
                  fileType: formatInfo,
                  base64File: base64String,
                };
                props.onImageSelected(imageUpload);
              };

              reader.readAsDataURL(file);
            }}
          ></input>
        </div>
      ) : (
        <div>
          <Lottie
            style={{ width: 80 }}
            options={{ animationData: animationData }}
          />
        </div>
      )}
    </div>
  );
}
