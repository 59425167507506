import type { PropsWithChildren } from "react";
import { cn } from "../utils/cn";

type Props = PropsWithChildren<{
  className?: string;
}>;

export const SecondarySectionTitle = ({ children, className }: Props) => (
  <p className={cn("text-xs text-gray-6", className)}>{children}</p>
);

SecondarySectionTitle.displayName = "SecondarySectionTitle";
